import { API, RequiredQueryOptions } from "./network/API";
import { UTCDate } from "./orderAPI";

export interface Attribution {
  name: string;
  country: string;
  lastOrder: UTCDate;
  orderCount: number;
  orderValue: number;
}

const QUERY_KEY = "attributions";

export const attributionAPI = {
  QUERY_KEY: QUERY_KEY,

  fetchAttributions: (): RequiredQueryOptions<Attribution[]> => ({
    queryKey: [QUERY_KEY],
    queryFn: () => API.get<Attribution[]>(`/admin/attribution/overview`),
  }),
};

import { Layout } from "../../layout/Layout";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  TableCellProps,
} from "@mui/material";
import { AttributionRow } from "./components/AttributionRow";
import { SortedTableCell } from "../../components/Table/SortedTableCell";
import { SortDirection } from "../../api/network/Page";
import { createSortFunction } from "../../utils";
import { Attribution, attributionAPI } from "../../api/attributionAPI";

export const ATTRIBUTION_LIST_PAGE_ROUTE = "/attributions";

export const AttributionListPage = () => {
  return (
    <Layout
      fullHeight
      title="Attributions"
      skeleton={<PageSkeleton />}
      skeletonGap={2}
    >
      <Inner />
    </Layout>
  );
};

const Inner = () => {
  const [sortField, setSortField] = useState<keyof Attribution>("orderValue");
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.DESC
  );

  const { data: unsortedData } = useSuspenseQuery(
    attributionAPI.fetchAttributions()
  );

  const data = useMemo(
    () => unsortedData?.sort(createSortFunction(sortField, sortDirection)),
    [unsortedData, sortField, sortDirection]
  );

  const handleSortChange = (field: keyof Attribution) => {
    if (field === sortField) {
      setSortDirection(
        sortDirection === SortDirection.DESC
          ? SortDirection.ASC
          : SortDirection.DESC
      );
    } else {
      setSortField(field);
      setSortDirection(SortDirection.DESC);
    }
  };

  return (
    <TableContainer sx={{ flex: 1 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {sortableHeaders.map((header) => (
              <SortedTableCell
                key={header.id}
                field={header.id}
                currentSortField={sortField}
                currentSortDirection={sortDirection}
                onSortChange={handleSortChange}
                align={header.align}
              >
                {header.label}
              </SortedTableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((entry) => (
            <AttributionRow key={entry.name} attribution={entry} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const sortableHeaders: {
  id: keyof Attribution;
  label: string;
  align?: TableCellProps["align"];
}[] = [
  { id: "name", label: "Name", align: "left" },
  { id: "country", label: "Country", align: "left" },
  {
    id: "orderCount",
    label: "Orders",
    align: "right",
  },
  {
    id: "orderValue",
    label: "Revenue",
    align: "right",
  },
  {
    id: "lastOrder",
    label: "Latest order",
    align: "right",
  },
];

const PageSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" height={30} />
      {[...Array(5)].map((_, i) => (
        <Skeleton key={i} variant="rounded" height={50} />
      ))}
    </>
  );
};

import {
  Box,
  Card,
  CardContent,
  Fab,
  Grid2 as Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { Layout } from "../../layout/Layout";
import {
  FunctionComponent,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { Fullscreen } from "@mui/icons-material";
import { LatestOrdersList } from "./components/LatestOrdersList";
import { WeeklyOrdersChartSimple } from "./components/WeeklyOrdersChartSimple";
import { StatisticsGrid } from "./components/StatisticsGrid";

export const DASHBOARD_PAGE_ROUTE = "/dashboard";

export const DashboardPage = () => {
  const layoutRef = useRef<HTMLDivElement>(null);
  return (
    <Layout
      title="Offers"
      skeleton={<PageSkeleton />}
      skeletonGap={2}
      ref={layoutRef}
      background="background.default"
    >
      <Inner layoutRef={layoutRef} />
    </Layout>
  );
};

const Inner: FunctionComponent<{
  layoutRef: React.RefObject<HTMLDivElement>;
}> = ({ layoutRef }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const makeFullscreen = () => {
    if (layoutRef.current) {
      layoutRef.current.requestFullscreen();
    }
  };

  useEffect(() => {
    const onFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", onFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, [layoutRef]);

  return (
    <Box ref={containerRef}>
      <Typography
        variant="h5"
        fontWeight={700}
        gutterBottom
        sx={{
          ml: {
            xs: 1,
            sm: 0,
          },
        }}
      >
        Dashboard
      </Typography>

      <Box>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid
            container
            spacing={2}
            size={{ xs: 12, sm: 7, lg: 8 }}
            alignItems="stretch"
            justifyContent="flex-start"
          >
            <StatisticsGrid />
            <Grid size={12}>
              <Suspense fallback={<Skeleton variant="rounded" height={340} />}>
                <Card>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 16 }}
                      color="text.secondary"
                      fontWeight={700}
                      gutterBottom
                    >
                      Sales last 7 days
                    </Typography>
                    <Box sx={{ mx: -3 }}>
                      <WeeklyOrdersChartSimple />
                    </Box>
                  </CardContent>
                </Card>
              </Suspense>
            </Grid>
          </Grid>
          <Grid container spacing={2} size={{ xs: 12, sm: 5, lg: 4 }}>
            <Grid size={12}>
              <LatestOrdersList />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {!isFullscreen && (
        <Fab
          color="primary"
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            position: "fixed",
            bottom: {
              xs: 80,
              md: 20,
            },
            right: 20,
          }}
          variant="extended"
          onClick={makeFullscreen}
          size="small"
        >
          <Fullscreen sx={{ mr: 1 }} /> Fullscreen
        </Fab>
      )}
    </Box>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" height={50} width={140} sx={{ mt: 2 }} />
    </>
  );
};
